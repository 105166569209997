.pricingtable--fullwidth {
  max-width: inherit;
  padding: 0;
  margin-bottom: $spacer*1.5;

  ul {
    margin-bottom: $spacer*.5;
  }

  li {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.custom-option--bgcolors {
  margin-bottom: $spacer;
  margin-top: $spacer;

  @include media-breakpoint-up(xl){
    flex-wrap:nowrap !important;
    >[class^="col-"]{
      max-width:1000px;
      flex:1 1 30%;
      white-space:nowrap;
  }
  }
 
  .custom-option__item {
    align-items: center;
    background-color: $primary;
    border: none;
    color: $white;
    // width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;
    flex-wrap: wrap;

    &.plan-basic {
      --color: #00aff0;
      background-color: map-get($pricing-color, 'basic');   
    }

    &.plan-pro {
      --color: #03B5AA;
      background-color: map-get($pricing-color, 'pro');
    }

    &.plan-premium {
      --color: #F75358;
      background-color: map-get($pricing-color, 'premium');
    }

    &.plan-enterprise {
      --color: #6A47DA;
      background-color: map-get($pricing-color, 'enterprise');
    }

    @include media-breakpoint-up(xl) {
      margin: 0;
    }
  }

  .custom-option__label {
    &::before {
      background: $white;
    }
  }

  .custom-option__sublabel {
    font-weight: 600;
    margin-left: 10px;
    font-size: 16px;
      flex:1 1 100%;
      margin-left: 30px; 
    
  }

  input[type="radio"]:checked~.custom-option__label:before,
  input[type="checkbox"]:checked~.custom-option__label:before {
    background: $white;
  }

  input[type="radio"]:checked~.custom-option__label:after,
  input[type="checkbox"]:checked~.custom-option__label:after {
    color: var(--color);
  }
}

#addressblock {
  display: none;
  margin-top: $spacer;

  >h3 {
    @include font-size($font-size-lg);
  }
}

.chooseplanpage {
  #newCCForm {
    margin-bottom: $spacer*1.5;
  }

  .cards__title {
    font-weight: 600;
    margin-bottom: $spacer*2;
  }
}

.block-pricedetails {
  background: #F8FDFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacer*1.5;

  &__head {
    font-weight: 600;
    @include font-size($font-size-lg);
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding-bottom: $spacer;

    a {
      font-weight: 400;
      @include font-size($font-size-base);
    }
  }
}

.flexlisting {
  border-top: 1px solid $border-color;

  li {
    display: flex;
    justify-content: space-between;
    margin: ($spacer*.5) 0;

    &:last-child {
      border-top: 1px solid $border-color;
      padding-top: $spacer;
      font-weight: 600;
    }
  }
}

//membership page

.plan-details {
  display: none;

  &.active {
    display: block;
  }
}

.plan-details {
  .show-form {
    &[aria-expanded="true"] {
      display: none ;
    }
    &[aria-expanded="false"] {
      display: inline-block;
    }
  }
}